var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{class:_vm.$style.card},[_c('Table',{attrs:{"headers":_vm.cols,"items":_vm.black_list,"options":_vm.table_options,"custom-sort":_vm.prevent_sort,"loading":_vm.black_list_loading,"no-data-text":"Контагенты, удовлетворяющие выбранным параметрам, отсутствуют","hide-default-footer":"","not_resizeble":""},on:{"update:options":function($event){_vm.table_options=$event}},scopedSlots:_vm._u([{key:"item.name_company",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.name_company)+" ")])]}},{key:"item.inn_organization",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.inn_organization)+" ")])]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.city)+" ")])]}},{key:"item.name_organization",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.name_organization)+" ")])]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination_props":_vm.pagination_props},on:{"change_page":function($event){return _vm.upd_pagination('current_page', $event)},"page_size_changed":function($event){return _vm.upd_pagination('limit', $event)}}})]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }